<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="动作评估"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <div style="margin: 16px;">
            <Button round block type="info" @click="addIdentify">上传图片/视频</Button>
        </div>
        <Divider v-if="!is_diary"/>
        <Collapse v-model="activeName" accordion>
            <div v-for="(val,key) in typeHash" :key="key">

                <CollapseItem :title="val" :name="val">
                    <cell-group>
                        <cell v-for="(idx,index) in crfsHash[key][1]" :title="idx.video_name_cn" :key="index"
                              is-link @click="showIdentifyReport(idx)"  >
                            <template #label  >
                                <!--                    <div class="title"  >{{idx.send_title}}</div>-->
                                <div v-if="idx.report_state == 0">(等待评估)</div>
                                <div class="is_completed" v-if="idx.report_state == 1">
                                    (正在评估)
                                </div>
                                <div v-if="idx.report_state == 2">(已出报告)</div>
                                <div v-if="idx.report_state == 3">(评估失败)</div>
                                <div v-if="idx.id">创建时间：{{parseTime(idx.created_at,'{y}-{m}-{d}')}}</div>

                            </template>
                            <!--                <template   >-->
                            <!--                    <span class="is_completed" v-if="idx.sf_status==1" >已完成</span>-->
                            <!--                    <span class="is_uncompleted" v-if="idx.sf_status==0" >未完成</span>-->
                            <!--                </template>-->
                        </cell>
                    </cell-group>

                </CollapseItem>
            </div>
        </Collapse>

        <empty v-if="nodata&&!user_empty" description="查无数据" />
        <Loading type="spinner" color="#1989fa" size="24px" vertical v-if="user_empty" style="margin-top: 40%">获取授权中...</Loading>

        <RLFooter/>
    </div>
</template>

<script>

    import {Cell,Empty,CellGroup,Loading,Button,Divider,Collapse, CollapseItem } from 'vant';
    import {getIdentify} from '@/api/identify';
    // import {getBaseInfo} from '@/api/patient/baseinfo';
    // import {setToken,removeToken} from '@/utils/auth';
    import {getUser} from '@/api/user';

    export default {
        name: 'QueIdx',
        components: {
            Cell,Empty,CellGroup,Loading,Button,Divider,Collapse, CollapseItem
        },
        data(){
            return {
                active:0,
                followcrfs:[],
                nodata:true,
                user:{
                    userName:'',
                    nickName:'',
                    idCard:'',
                    phone:'',
                    gender:'1',
                    birthday:'',
                    id:'',
                    pid:''
                },
                user_empty:true,
                follow_time:null,
                is_diary:true,
                typeHash:{},
                crfsHash:{},
                activeName:'-1'

            };
        },
        computed:{

        },
        methods:{
            showIdentifyReport(idx){
                // this.$router.push({path: '/followcrf/'+idx.id});
                this.$router.push({path: '/identify_report/',query:{id:idx.id,video_name:idx.video_name}});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },
            addIdentify(){
                this.$router.push({path: '/upload_identify/',query:{}});
            },

            initData(){
                // let query = this.$route.query;
                // this.follow_time=query.followtime;
                getIdentify({}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        // this.loading=true;
                        this.active=response.data.length;

                        this.typeHash=response.data.reDate;
                        this.crfsHash=response.data.data;
                        console.log(response.data)
                        console.log(this.typeHash)
                        console.log(this.crfsHash)
                        for(let key in this.typeHash){
                            this.activeName = this.typeHash[key]
                            break;
                        }
                        this.nodata = false

                    }
                });
                // let follow_time=this.parseTime(new Date(),'{y}-{m}-{d}');

            },
        }
        ,
        created() {
            //生产环境
            // var query = this.$route.query;
            // if(query.code || this.getQueryString('code')){
            //     // console.log("code")
            //     // console.log(query.code)
            //     // console.log(this.getQueryString('code'))
            //     //this.$store.commit('updateOpenid','123456');
            //     this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
            //     setToken(query.code?query.code : this.getQueryString('code'));
            //     getUser().then((res) => {
            //         console.info(res.data);
            //
            //         if(res.data){
            //             this.$store.commit('updateOpenid',res.data.openid);
            //             this.$store.commit('updatePid',res.data.id);
            //             this.$store.commit('updateBaseInfo',res.data);
            //             if(res.data.name !=''){
            //                 this.substatus = true
            //             }
            //             this.user.id=res.data.id;
            //             this.user.name=res.data.name;
            //             this.user.birthday=res.data.birthday;
            //             // this.user.idCard=response.data.idCard;
            //             this.user.telephone=res.data.telephone;
            //             this.user.gender=res.data.gender+'';
            //             this.user.address=res.data.address;
            //             this.user.detail_address=res.data.detail_address;
            //             // this.initBaseInfo();
            //             this.user_empty = false
            //             this.initData()
            //
            //         }
            //     });
            //     //getBaseInfo({}).then((response) => {
            //     //   console.info(response);
            //     // });
            // }else{
            //     removeToken();
            // }
            // 开发环境
            getUser().then((res) => {
                console.info(res.data);

                if(res.data){
                    this.$store.commit('updateOpenid',res.data.openid);
                    this.$store.commit('updatePid',res.data.id);
                    this.$store.commit('updateBaseInfo',res.data);
                    if(res.data.name !=''){
                        this.substatus = true
                    }
                    this.user.id=res.data.id;
                    this.user.name=res.data.name;
                    this.user.birthday=res.data.birthday;
                    // this.user.idCard=response.data.idCard;
                    this.user.telephone=res.data.telephone;
                    this.user.gender=res.data.gender+'';
                    this.user.address=res.data.address;
                    this.user.detail_address=res.data.detail_address;
                    // this.initBaseInfo();
                    this.user_empty = false

                    this.initData();

                }
            });
            // this.initBaseInfo();
            /*初始化列表*/
            // this.initidx();

        },
    }
</script>

<style scoped>

    .is_completed{
        color: #1bde1a;
        /*color: aqua;*/
    }
    .is_uncompleted{
        color:red;
    }

</style>
